.learning-advantages {
  padding: 48px 0;
  margin: 0 0 -48px;
  overflow: hidden;

  &-grid {
    display: grid;
    gap: 32px;
    grid-template-columns: auto;
    grid-auto-rows: auto;
    grid-template-areas:
      'list'
      'button';

    @media (min-width: 768px) {
      gap: 32px 35px;
      grid-template-areas:
        'list'
        'aside'
        'button';
    }

    @media (min-width: 992px) {
      grid-template-columns: 540px 1fr;
      grid-template-areas:
        'list   aside'
        'button aside';
    }
  }

  &-list-wrap {
    grid-area: list;
  }

  &-list {
    width: 100%;
    padding: 10px 16px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    background: #fff;
    box-shadow: 0 24px 80px rgba(55, 87, 67, .1);

    @media (min-width: 768px) {
      padding: 48px 30px 32px;
    }
  }

  &-item {
    display: flex;
    flex-direction: column;
    gap: 12px;

    @media (min-width: 768px) {
      align-items: center;
      flex-direction: row;
      gap: 16px;
    }
  }

  &-icon {
    width: 24px;
    font-size: 20px;
    font-family: 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji', sans-serif;
    font-style: normal;
    line-height: 1;
    text-align: center;
    display: inline-flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    aspect-ratio: 1 / 1;

    @media (min-width: 768px) {
      width: 32px;
      font-size: 28px;
    }
  }

  &-label {
    font-size: 16px;
    font-weight: 400;
    line-height: 160%;
    color: #160330;

    @media (min-width: 768px) {
      font-size: 18px;
    }
  }

  &-button-wrap {
    display: flex;
    grid-area: button;

    @media (min-width: 768px) {
      width: fit-content;
    }
  }

  &-aside-wrap {
    display: none;
    grid-area: aside;

    @media (min-width: 768px) {
      display: block;
    }
  }

  &-aside {
    display: block;
  }

  &-aside-img {
    margin: 0 auto;
    border-radius: 20px;
    display: block;
    box-shadow: 0 4px 10px rgba(#000, .25);
  }

  @media (min-width: 768px) {
    padding: 120px 0;
    margin: 0 0 -120px;
  }
}
