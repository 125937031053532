.class-structure {
  padding: 48px 0;
  overflow: hidden;
  background: #f5f7fa;

  &-grid {
    display: grid;
    align-items: center;
    gap: 28px;
    grid-auto-rows: auto;
    grid-template-columns: 1fr;
    grid-template-areas:
      'list'
      'graphics'
      'lessons';

    @media (min-width: 768px) {
      gap: 32px;
      grid-template-areas:
        'list'
        'lessons';
    }

    @media (min-width: 1200px) {
      gap: 80px 30px;
      grid-template-columns: 1fr auto;
      grid-template-areas:
        'list    graphics'
        'lessons lessons';
    }
  }

  &-list-wrap {
    grid-area: list;
  }

  &-list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 12px;

    @media (min-width: 768px) {
      gap: 24px;
      grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 1200px) {
      gap: 24px 30px;
    }
  }

  &-item {
    padding: 12px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    background: #fff;
    gap: 12px;
    box-shadow: 0 24px 80px rgba(55, 87, 67, .1);

    @media (min-width: 768px) {
      padding: 16px;
      gap: 16px;
    }
  }

  &-item-icon {
    width: 32px;
    height: 32px;
    border-radius: 8px;
    font-size: 20px;
    line-height: 1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e3cffc;

    @media (min-width: 768px) {
      width: 40px;
      height: 40px;
      font-size: 24px;
    }
  }

  &-item-title {
    font-size: 16px;
    font-weight: 400;
    line-height: 160%;
    color: #160330;

    @media (min-width: 768px) {
      font-size: 18px;
    }
  }

  &-graphics-wrap {
    grid-area: graphics;

    @media (min-width: 768px) {
      display: none;
    }

    @media (min-width: 1200px) {
      display: block;
    }
  }

  &-graphics {
    position: relative;
    width: 328px;
    height: 274px;
    margin: 0 auto;

    @media (min-width: 1200px) {
      width: 350px;
      height: 336px;
    }
  }

  &-graphics-circle {
    position: absolute;
    right: -49px;
    bottom: 0;
    width: 274px;
    height: 274px;
    border-radius: 50%;
    background: #fff;

    @media (min-width: 1200px) {
      right: -32px;
      width: 277px;
      height: 277px;
    }
  }

  &-graphics-teacher {
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 23px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background: #823cdc;

    @media (min-width: 1200px) {
      padding: 32px 35px;
      gap: 10px;
    }
  }

  &-graphics-student {
    position: absolute;
    top: 0;
    right: 0;
    padding: 23px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    background: #3da2f5;

    @media (min-width: 1200px) {
      padding: 32px 35px;
      gap: 10px;
    }
  }

  &-graphics-time {
    position: absolute;
    top: 21px;
    left: 75px;
    width: 140px;
    height: 140px;
    padding: 13px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    background: #ffb466;

    @media (min-width: 1200px) {
      top: 24px;
      left: 62px;
      width: 160px;
      height: 160px;
      padding: 23px;
    }
  }

  &-graphics-img {
    border-radius: 50%;
    display: block;
  }

  &-graphics-title {
    font-size: 14px;
    font-weight: 400;
    line-height: 120%;
    color: #fff;
    text-align: center;
    letter-spacing: .05em;
    text-transform: uppercase;

    @media (min-width: 1200px) {
      font-size: 16px;
    }
  }

  &-graphics-icons {
    font-size: 18px;
    line-height: 1;

    @media (min-width: 1200px) {
      font-size: 20px;
    }
  }

  &-graphics-text {
    font-size: 12px;
    font-weight: 600;
    line-height: 120%;
    color: #fff;
    text-align: center;
    display: block;

    @media (min-width: 1200px) {
      font-size: 14px;
    }
  }

  &-lessons-wrap {
    grid-area: lessons;
  }

  &-lessons {
    --animation-play-state: paused;

    padding: 16px;
    border-radius: 16px;
    display: grid;
    align-items: start;
    background: #fff;
    box-shadow: 0 24px 80px rgba(55, 87, 67, .1);
    gap: 12px;
    grid-template-columns: 1fr 71px;
    grid-template-areas:
      'title    cup'
      'progress cup';

    @media (min-width: 768px) {
      padding: 50px 26px;
      align-items: end;
      gap: 54px 16px;
      grid-template-columns: 1fr 81px;
    }

    @media (min-width: 1200px) {
      padding: 42px 48px;
      gap: 32px;
    }
  }

  &-lessons-title {
    font-size: 18px;
    font-weight: 600;
    line-height: 160%;
    color: #160330;
    grid-area: title;

    @media (min-width: 768px) {
      font-size: 24px;
    }

    @media (min-width: 1200px) {
      font-size: 32px;
    }
  }

  &-progress {
    grid-area: progress;
  }

  &-progress-content {
    position: relative;
    width: 100%;
    border-radius: 100px;
    display: block;
    overflow: hidden;
    background-color: #e8e6ea;

    &:before {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      content: '';
      background-image: url(./assets/class-structure-dots-mobile.svg);
      background-size: 20% auto;
      background-position: 0% 50%;
    }

    @media (min-width: 768px) {
      &:before {
        background-image: url(./assets/class-structure-dot.svg);
        background-size: calc(100% / 5 / 7) auto;
      }
    }

    @media (min-width: 1200px) {
      &:before {
        background-size: calc(100% / 5 / 9) auto;
      }
    }
  }

  @keyframes progressAnimation {
    from {
      max-width: 0%;
    }

    to {
      max-width: 100%;
    }
  }

  &-progress-line {
    width: 100%;
    height: 28px;
    border-radius: 100px;
    display: block;
    animation: progressAnimation 1s linear;
    animation-fill-mode: both;
    animation-play-state: var(--animation-play-state);
    background: linear-gradient(90deg, #f1e9fb 0%, #823cdc 100%);

    @media (min-width: 1200px) {
      height: 32px;
    }
  }

  &-progress-steps {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
  }

  &-progress-step {
    position: relative;
    width: 0;
    padding: 16px 0 0;

    &:before {
      position: absolute;
      left: -1px;
      bottom: calc(100% - 16px);
      width: 2px;
      height: calc(16px + 28px);
      content: '';
      background: #111;
      opacity: .1;
    }
  }

  @keyframes progressValueAnimation {
    from {
      border-color: #d9d9d9;
    }

    to {
      border-color: #823cdc;
    }
  }

  &-progress-value {
    width: 28px;
    height: 28px;
    margin: 0 auto;
    border: 2px solid #d9d9d9;
    border-radius: 50%;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    color: #160330;
    text-align: center;
    display: block;
    transform: translateX(-50%);
    animation: progressValueAnimation .2s ease;
    animation-fill-mode: both;
    animation-play-state: var(--animation-play-state);

    @media (min-width: 768px) {
      width: 32px;
      height: 32px;
      font-size: 18px;
      line-height: 28px;
    }
  }

  &-progress-title {
    width: fit-content;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    color: #160330;
    text-align: center;
    display: block;
    white-space: nowrap;
    transform: translateX(-50%);

    @media (min-width: 768px) {
      font-size: 16px;
    }
  }

  &-cup {
    padding: 12px 0 6px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    grid-area: cup;
    gap: 12px;

    @media (min-width: 768px) {
      gap: 20px;
    }
  }

  &-cup-img {
    width: 48px;
    max-width: 100%;
    display: block;

    @media (min-width: 768px) {
      width: 70px;
    }
  }

  &-cup-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 160%;
    color: #160330;
    text-align: center;

    @media (min-width: 768px) {
      font-size: 16px;
    }
  }

  @media (min-width: 768px) {
    padding: 120px 0;
  }
}
