@keyframes fadeIn {
  0% {
    display: block;
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.online-learning-benefits {
  padding: 24px 0 48px;
  background: #f5f7fa;

  &-container {
    margin: 0 0 32px;
    display: flex;
    flex-direction: column;
    gap: 22px;

    @media (min-width: 768px) {
      margin: 0 0 80px;
      gap: 32px;
    }

    @media (min-width: 1200px) {
      margin: 0 0 70px;
      align-items: center;
      flex-direction: row;
      gap: 40px;
    }
  }

  &-images {
    position: relative;
    width: 100%;
    aspect-ratio: 1 / 1;

    @media (min-width: 1200px) {
      width: 445px;
    }
  }

  &-image {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 60px;
    inset: 0;
    object-fit: cover;
    object-position: 50% 50%;
    opacity: 0;
    transition: opacity .4s ease;

    &.is-active {
      opacity: 1;
    }
  }

  &-slider {
    flex: 1;
    overflow: hidden;
  }

  &-slide {
    display: block;
    flex: 1;
  }

  &-slide-title {
    margin: 0 0 22px;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    color: #160330;

    @media (min-width: 768px) {
      margin: 0 0 32px;
      font-size: 32px;
      line-height: 160%;
    }

    @media (min-width: 1200px) {
      margin: 0 0 16px;
      font-size: 24px;
      font-weight: 600;
      line-height: 33px;
    }
  }

  &-slide-text {
    margin: 0 0 32px;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    color: #160330;

    @media (min-width: 768px) {
      font-size: 18px;
      line-height: 160%;
    }

    @media (min-width: 1200px) {
      line-height: 170%;
    }
  }

  &-additional {
    display: block;
  }

  &-additional-title {
    margin: 0 0 16px;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    color: #160330;

    @media (min-width: 768px) {
      margin: 0 0 32px;
      font-size: 32px;
    }

    @media (min-width: 1200px) {
      margin: 0 0 16px;
      font-size: 24px;
      font-weight: 600;
      line-height: 33px;
    }
  }

  &-additional-text {
    margin: 0 0 16px;
    font-size: 16px;
    font-weight: 400;
    line-height: 170%;
    color: #160330;

    @media (min-width: 768px) {
      margin: 0 0 32px;
      font-size: 18px;
      line-height: 160%;
    }

    @media (min-width: 1200px) {
      margin: 0 0 16px;
      line-height: 170%;
    }
  }

  &-additional-more {
    margin: 16px 0 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    transition: grid-template-rows 2s ease;

    &.is-hidden {
      grid-template-rows: 0fr;
    }
  }

  &-additional-container {
    overflow: hidden;
  }

  &-additional-button-wrap {
    &.is-hidden {
      display: none;
    }
  }

  @media (min-width: 768px) {
    padding: 106px 0 60px;
  }

  @media (min-width: 1200px) {
    padding: 120px 0;
  }
}
