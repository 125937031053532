.cta {
  display: block;

  &-block {
    padding: 32px 16px 40px;
    border-radius: 16px;
    color: #fff;
    text-align: center;
    display: grid;
    justify-content: center;
    grid-template-areas:
      'icons'
      'title'
      'text'
      'button';
    background: #823cdc;
    gap: 28px;

    @media (min-width: 768px) {
      padding: 80px 40px;
      text-align: left;
      align-items: start;
      grid-auto-rows: auto;
      gap: 24px 30px;
      grid-template-columns: auto 1fr;
      grid-template-areas:
        'icons title'
        'icons text'
        'icons button';
    }

    @media (min-width: 1200px) {
      align-items: center;
      grid-template-columns: auto 1fr auto;
      grid-template-areas:
        'icons title button'
        'icons text button';
    }
  }

  &-icons {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    border-radius: 50%;
    font-size: 24px;
    line-height: 1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-area: icons;
    background: #fff;

    @media (min-width: 768px) {
      width: 120px;
      height: 120px;
      font-size: 32px;
    }
  }

  &-title {
    font-size: 18px;
    font-weight: 600;
    line-height: 120%;
    display: block;
    grid-area: title;

    @media (min-width: 768px) {
      font-size: 32px;
    }
  }

  &-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 120%;
    display: block;
    grid-area: text;

    @media (min-width: 768px) {
      font-size: 24px;
    }
  }

  &-button-wrap {
    grid-area: button;
  }
}
