.comparison {
  padding: 48px 0;
  background: #f5f7fa;

  &-table {
    position: relative;
    padding: 16px;
    border-radius: 16px;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 26px 26px 26px;
    grid-auto-rows: auto;
    background: #fff;
    box-shadow: 0 24px 80px rgba(55, 87, 67, .1);
    gap: 26px 24px;

    @media (min-width: 768px) {
      padding: 32px 24px;
      gap: 24px 20px;
      grid-template-columns: 1fr auto auto auto;
    }

    @media (min-width: 1200px) {
      padding: 20px 40px 35px;
      margin: 15px 0 0;
      gap: 24px 50px;
    }
  }

  &-logo {
    min-height: 30px;
    margin: 0 -12px;
    font-size: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(./assets/comparison-logo.svg) 50% 50% no-repeat;

    @media (min-width: 768px) {
      margin: 0;
      font-size: 18px;
      font-weight: 700;
      line-height: 25px;
      color: #823cdc;
      background: none;
    }

    @media (min-width: 1200px) {
      font-size: 24px;
      line-height: 33px;
    }
  }

  &-head {
    margin: 0 -12px;
    font-size: 12px;
    font-weight: 700;
    line-height: 120%;
    color: #160330;
    text-align: center;
    overflow-wrap: break-word;

    @media (min-width: 768px) {
      margin: 0;
      font-size: 18px;
      line-height: 25px;
    }

    @media (min-width: 1200px) {
      font-size: 20px;
      line-height: 27px;
    }
  }

  &-title {
    display: flex;
    flex-direction: column;
    gap: 12px;

    &:after {
      position: absolute;
      left: 16px;
      right: 16px;
      border-top: 1px solid #160330;
      transform: translateY(-12px);
      content: '';
      opacity: .1;
    }

    @media (min-width: 768px) {
      margin: 0 -20px 0 0;
      flex-direction: row;

      &:after {
        left: 24px;
        right: 24px;
      }
    }

    @media (min-width: 1200px) {
      margin: 0;

      &:after {
        left: 40px;
        right: 40px;
      }
    }
  }

  &-icon {
    width: 20px;
    font-size: 20px;
    line-height: 1;

    @media (min-width: 768px) {
      width: 24px;
      font-size: 24px;
    }
  }

  &-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 160%;
    color: #160330;

    @media (min-width: 768px) {
      font-size: 18px;
    }
  }

  &-cell {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-img {
    display: block;
  }

  @media (min-width: 768px) {
    padding: 130px 0 30px;
  }

  @media (min-width: 1200px) {
    padding: 130px 0 120px;
  }
}
