.personaly-course {
  padding: 48px 0;
  overflow: hidden;
  background: #823cdc;

  .row {
    @media (min-width: 1200px) {
      display: flex;
      align-items: flex-start;
      gap: 64px;
    }
  }

  .cards {
    padding: 0 20px;
    margin: -8px -20px 0;
    display: flex;
    overflow: auto;
    gap: 8px;

    @media (min-width: 768px) {
      align-items: flex-start;
      overflow: hidden;
      gap: 50px;
    }

    @media (min-width: 1200px) {
      width: 590px;
      min-width: 590px;
      padding: 0;
      margin: 0;
    }
  }

  .card {
    --head-color: #3da2f5;

    width: 270px;
    min-width: 270px;
    padding: 32px 16px;
    border-radius: 16px;
    background: #fff;
    box-shadow: 0 24px 80px rgba(55, 87, 67, .1);

    &.is-second {
      --head-color: #ffb466;
    }

    @media (min-width: 768px) {
      padding: 42px 16px;

      &.is-second {
        margin: 60px 0 0;
      }
    }
  }

  .header {
    padding: 12px 22px;
    margin: 0 0 24px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 4px;
    background: var(--head-color);
  }

  .icon {
    font-size: 24px;
    line-height: 1;

    @media (min-width: 768px) {
      font-size: 32px;
    }
  }

  .title {
    font-size: 14px;
    font-weight: 600;
    line-height: 160%;
    color: #fff;

    @media (min-width: 768px) {
      font-size: 16px;
    }
  }

  .list {
    display: flex;
    flex-direction: column;
    gap: 1px;
  }

  .item {
    position: relative;
    padding: 0 0 0 14px;
    font-size: 18px;
    font-weight: 400;
    line-height: 160%;
    color: #160330;

    &:after {
      position: absolute;
      top: 11px;
      left: 0;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      content: '';
      background: #823cdc;
    }
  }

  .circle {
    display: none;

    @media (min-width: 768px) {
      top: -100px;
      display: block;
    }

    @media (min-width: 1200px) {
      top: -160px;
    }
  }

  @media (min-width: 768px) {
    padding: 120px 0 110px;
  }
}
