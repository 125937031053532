.cost-of-lessons {
  position: relative;
  z-index: 1;
  padding: 48px 0;
  color: #111;
  overflow: hidden;

  .container {
    @media (min-width: 1200px) {
      display: flex;
      align-items: center;
    }
  }

  .info {
    margin: 0 0 32px;
    flex-shrink: 0;

    @media (min-width: 1200px) {
      width: 485px;
      padding: 0;
      margin: 0 25px 60px 0;
    }
  }

  .text-info {
    &:nth-of-type(2) {
      margin: 0 0 12px;
    }
  }

  .title-info,
  .item-info {
    font-size: 16px;
    line-height: 160%;

    @media (min-width: 768px) {
      font-size: 18px;
    }
  }

  .title-info {
    margin: 0 0 12px;
    font-weight: 700;
  }

  .item-info {
    position: relative;
    padding: 0 0 5px 14px;

    &:after {
      position: absolute;
      top: 9px;
      left: 0;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      content: '';
      background: #823CDC;
    }

    &:last-of-type {
      padding: 0 0 0 14px;
    }

    @media (min-width: 768px) {
      padding: 0 0 3px 14px;

      &:after {
        top: 11px;
      }
    }
  }

  .button-wrapper {
    margin: 28px 0;
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (min-width: 768px) {
      width: fit-content;
      margin: 32px 0 0;
      flex-direction: row;
    }
  }

  .guarantee {
    padding: 15px 45px;
    border-radius: 16px;
    font-size: 14px;
    line-height: 1.3;
    color: #160330;
    display: flex;
    background: #FFE7A9;
    letter-spacing: 0.28px;
    text-wrap: balance;
    gap: 10px;

    &:before {
      width: 32px;
      min-width: 32px;
      height: 32px;
      display: block;
      content: '';
      background: url(./assets/shield.png) 50% 50% / contain no-repeat;
    }

    @media (min-width: 768px) {
      padding: 15px 17px;
    }
  }

  // Price

  .price-list {
    display: flex;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }

    @media (min-width: 1200px) {
      width: 600px;
      margin: -45px 0 0;
      flex-wrap: wrap;
      align-items: flex-start;
      overflow: visible;
      flex-shrink: 0;
    }
  }

  .price-el {
    position: relative;
    width: 280px;
    min-height: 315px;
    margin: 0 16px 0 0;
    border-radius: 16px;
    flex-shrink: 0;
    background: #fff;

    @media (min-width: 1200px) {
      width: 280px;
      margin: 0 40px 40px 0;
      box-shadow: 0 24px 80px rgba(55, 87, 67, .1);

      &:nth-of-type(2) {
        margin: 53px 0 0;
      }

      &:nth-of-type(3) {
        margin: -15px auto 0 0;
      }

      &:nth-of-type(4) {
        margin: 40px 0 0;
      }
    }
  }

  .header {
    height: 110px;
    padding: 32px 24px;
    border-radius: 16px 16px 0 0;
    background: #f1e9fb;

    @media (min-width: 1200px) {
      padding: 32px 16px;
    }
  }

  .price-title {
    font-size: 16px;
    font-weight: 700;
    line-height: 160%;
    color: #111;

    @media (min-width: 768px) {
      font-size: 18px;
    }
  }

  .content {
    padding: 0 16px 50px;
  }

  .price-label {
    padding: 14px 24px;
    margin: -25px 0 0;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    background: #823cdc;
  }

  .count-lessons {
    font-size: 14px;
    font-weight: 600;
    line-height: 160%;
    color: #fff;

    @media (min-width: 768px) {
      font-size: 16px;
    }
  }

  .price-icon {
    font-size: 16px;
    line-height: 1;
    display: block;
  }

  .line {
    height: 1px;
    margin: 20px 0;
    background: rgba(#111, .4);
  }

  .price-text {
    margin: 0 0 20px;
    font-size: 12px;
    line-height: 160%;
  }

  .price-count-container {
    position: absolute;
    display: flex;
    justify-content: space-between;
    inset: auto 16px 20px;
  }

  .label-price-count {
    font-size: 12px;
    line-height: 160%;
  }

  .price-count {
    font-size: 14px;
    font-weight: 600;
    line-height: 160%;
    color: #111;

    @media (min-width: 768px) {
      font-size: 16px;
    }
  }

  .pay-u {
    margin: 18px 0 0;
    display: flex;
    align-items: center;
    gap: 16px;

    @media (min-width: 768px) {
      margin: 32px 0 0;
    }
  }

  .pay-u-title {
    width: 100px;
    font-size: 14px;
    font-weight: 400;
    line-height: 130%;
    color: #111;
  }

  .pay-u-icon {
    width: 80px;
  }

  @media (min-width: 768px) {
    padding: 120px 0;
  }

  @media (min-width: 1200px) {
    padding: 170px 40px 120px;
  }
}
